<template>
	<div>
		<Header ref="Header"/>
		<div class="container">
			<div class="topic-title">{{showData.title}}</div>
			<div class="topic-time">
				<div>{{showData.createTime}}</div>
			</div>
			<div class="topicDetail-list">
				<div v-html="showData.description"></div>
			</div>
			<div class="topic-title">相关场地推荐</div>
			<div class="topic-place-list flex">
				<div v-for="(item,index) in spaceList" :key="index">
					<div class="topic-place-item" @click="toSpace(item.placeId)">
						<img class="topic-place-pic" :src="item.placePicture"/>
						<div class="topic-place-name">【{{item.placeCity}}】{{item.placeName}}</div>
						<div class="topic-place-markbox flex">
							<div v-for="(lable,num) in item.placeMark">
								<div class="topic-place-mark">{{lable}}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Header from '@/components/header.vue';
	export default {
		components: {
			Header
		},
		data() {
			return {
				showData: {

				},
				spaceList:[],
				id:0,
				headerIndex:0,
				indexList:[
					{name:'专题推荐',herf:'topic',headerIndex:2},
					{name:'服务案例',herf:'service',headerIndex:3},
					{name:'最新动态',herf:'dynamic',headerIndex:4},
				],
			}
		},
		methods: {
			toPage(){
				for(let i=0;i<this.indexList.length;i++){
					if(this.headerIndex==this.indexList[i].headerIndex){
						this.$router.push({
							path:this.indexList[i].herf
						})
					}
				}
			},
			// 跳转到场地详情
			toSpace(spaceId){
				this.$router.push({
					path: 'introduction',
					query:{
						id:spaceId,
					},
				});
			},
		},
		mounted() {
			if(this.$route.query.index){
				this.headerIndex=this.$route.query.index;
			}
			this.$refs.Header.setIndex(this.$route.query.index);
			this.id=this.$route.query.id || 0;
			if(this.id<0){
				alert('传参有误');
				this.$router.go(-1);
			}
			this.$api.getTopicDetail(this.id).then(res=>{
				console.log(res,'专题详情');
				res.data.places.forEach(item=>{
					item.placeMark=item.placeMark.split(',')
				})
				this.showData=res.data.topic;
				this.spaceList=res.data.places;
			})
		}
	};
</script>

<style scoped>
	.topic-container{
		width: 100%;
		height: 100%;
		background-color: #FFFFFF;
	}
	.topic-title{
		width: 1100px;
		margin: 0 auto;
		padding-top: 20px;
		font-size: 20px;
		font-family: PingFang SC;
		font-weight: 500;
		color: #000000;
	}
	.topic-time{
		width: 1100px;
		margin: 0 auto;
		margin-top: 10px;
		font-size: 12px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #666666;
	}
	.topicDetail-list{
		width: 1100px;
		margin: 0 auto;
		margin-top: 30px;
		padding-bottom: 20px;
	}
	.topic-place-list{
		width: 1116px;
		margin-left: 51px;
		margin-top: 30px;
		padding-bottom: 30px;
		flex-wrap: wrap;
	}
	.topic-place-item {
		margin-bottom: 20px;
		width: 166px;
		height: 210px;
		/* margin-left: 20px; */
		background: #FFFFFF;
		cursor: pointer;
		box-shadow: 0px 4px 19px 0px rgba(4, 0, 0, 0.15);
		margin-right: 20px;
	}
	
	.topic-place-pic {
		width: 166px;
		height: 94px;
	}
	
	.topic-place-name {
		height: 30px;
		margin-top: 14px;
		padding-left: 7px;
		padding-right: 7px;
		font-size: 12px;
		font-family: PingFang SC;
		font-weight: 500;
		color: #333333;
	}
	
	.topic-place-markbox {
		width: 166px;
		margin-top: 10px;
		padding-left: 10px;
		padding-right: 10px;
		justify-content: space-between;
	}
	
	.topic-place-mark {
		padding-left: 10px;
		padding-right: 10px;
		height: 20px;
		background: #E4E7F8;
		border-radius: 10px;
		text-align: center;
		font-size: 12px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #12319B;
		line-height: 20px;
	}
</style>
